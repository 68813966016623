#about-cube-collab, #from-cube-collab, #connect-youtube {
    padding-top: 0;
    margin-top: 0;
  }
  
.notification{
    color:green;
    background:black;
    /* opacity:0.7; */
    position:fixed;
    bottom:0;
    width:100%;
  z-index: 1;
  padding: 2px;
  display: none;
  font-family: inter;
  overflow-x: hidden;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1.2px;
}

.heading {
    margin-left: 78px;
    z-index: 1;
    margin-top: 70px;
    width: 50%;
}

.heading2{
    margin-right: 90px;
    z-index: 1;
    width: 50%;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.mobileimage{
    display: none;
}
.text1 {
    display: flex;
    align-items: center;
    margin-left: 4rem;
}
.texth1{
    display: flex;
    align-items: center;
    margin-right: 7rem;
}

.rocket {
    width: 25px;
    height: 25px;
}

.text2 {
    display: flex;
    align-items: center;
    margin-left: 8rem;
}
.texth2{
    display: flex;
    align-items: center;
    margin-right: 11rem;
}
.title1,.title2,.title3,.titleh1,.titleh2,.titleh3{
    font-family: inter;
    text-decoration: none;
    font-size: 25px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0em;
}

.title1 {
    padding-left: 8px;
    max-width: 300px;
}
.titleh1{
    padding-right: 8px;
    max-width: 300px;
}

.title2 {
    
    padding-left: 8px;
    max-width: 300px;

}
.titleh2{
    padding-right: 8px;
    max-width: 300px;
}

.title3 {
   
    padding-left: 8px;
   
    max-width: 280px;
}
.titleh3{
    padding-right: 8px;
    max-width: 280px;
}




.header {

    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #191919;
}

.imageWrapper {
    position: relative;
    height: 100vh;
    overflow: hidden;
    top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.topGradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(54, 53, 53, 0) 10.35%, #191919 99.78%);
    pointer-events: none;
    z-index: 1;
}

.bottomGradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    pointer-events: none;
    z-index: 1;
    background: linear-gradient(180deg, rgba(54, 53, 53, 0) 10.35%, #191919 99.78%);
}


.backgroundimage {

    width: 75%;
    height: 120vh;
}

.imageContainer {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 10px;
    overflow: hidden;
    overflow-x: hidden;
}



.firstimage {
    max-width: 30%;
    height: 33%;
    top: 12vh;
}

.secondimage {
    max-width: 20%;
    height: 15%;
    bottom: 23vh;
}






@media (max-width: 1190px) {

    .heading {
        margin-left: 60px;
        z-index: 1;
        margin-top: 70px;
        width: 50%;
    }
    
    .text1 {
        margin-left: 15px;
    }

    .texth1{
        margin-right: 70px;
    }
    .text2 {
        margin-left: 25px;
    }
    .texth2{
        margin-right: 80px;
    }
  
    .backgroundimage {
        width: 85%;
        height: 120vh;
    }

    .firstimage {
        max-width: 33%;
        height: 33%;
        top: 12vh;
    }

    .secondimage {
        max-width: 22%;
        height: 15%;
        bottom: 23vh;
    }
   
}

@media only screen and (max-width: 992px) {


    .text1,.text2 {
        margin-left: 0px;
    }

    .texth1,.texth2 {
        margin-right: 0px;
    }
    
    .heading {
        margin-left: 20px;
    }
    .heading2 {
        margin-right: 45px;
    }

    .title1,.title2,.title3,.titleh1,.titleh2,.titleh3{
        font-size: 25px;

        line-height: 46px;

    }

    .title1,.title2,.title3 {
        padding-left: 10px;
        margin-left: 0rem;

    }
    .titleh1,.titleh2,.titleh3{
        padding-right: 10px;
        margin-right: 0rem;
    }
    .imageContainer {
      display: none;
    }


    .imageWrapper {
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
    }
    .mobileimage{
        display: flex;
    }
   

    .heading {
        margin-top: 190px;
    }
    
    .heading2{
        margin-top: 190px;
    }
    .rocketContainer {
        /* visibility: hidden; */
        /* display: none; */
    }
    .mobileimagesource{
        width: 100%;
        object-fit: cover;
        height: 580px;
    }

    .notification{
       display: flex;
    }

}



@media (max-width: 840px) {
   

    .title1,.title2,.title3,.titleh1,.titleh2,.titleh3{
        font-size: 21px;
    }

    .heading {
        margin-top: 197px;
    }
    
    .heading2{
        margin-top: 197px;
        margin-right: 50px;
    }
    .mobileimagesource{
        width: 100%;
        object-fit: cover;
        height: 560px;
    }
   
}

@media (max-width: 740px) {
   

    .heading {
        margin-top: 200px;
    }
    
    .heading2{
        margin-top: 200px;
    }

    .heading {
        margin-left: 20px;
    }
    .heading2 {
        margin-right: 40px;
    }

    .title1,.title2,.title3,.titleh1,.titleh2,.titleh3{
        font-size: 18px;
        line-height: 40px;
    }

    .title1,.title2,.title3 {
        padding-left: 10px;
        margin-left: 0rem;
    }

    .titleh1,.titleh2,.titleh3 {
        padding-right: 10px;
        margin-right: 0rem;
    }
    .mobileimagesource{
        width: 100%;
        object-fit: cover;
        height: 500px;
    }
   
}

@media (max-width: 650px) {
   

    .heading {
        margin-top: 70px;
    }
    .heading2 {
        margin-top: 70px;
    }
    .rocket {
        width: 15px;
        height: 15px;
    }
    .title1,.title2,.title3,.titleh1,.titleh2,.titleh3{
        font-size: 14px;

        line-height: 30px;

    }
    .heading {
        margin-top: 150px;
    }
    
    .heading2{
        margin-top: 150px;
    }
    .mobileimagesource{
        width: 100%;
        object-fit: cover;
        height: 400px;
    }

}


@media (max-width: 520px) {
    
    

    

    .title1,.title2,.title3,.titleh1,.titleh2,.titleh3{
        font-size: 11px;

        line-height: 30px;

    }
    .title1,.title2,.title3 {
        padding-left: 2px;
        margin-left: -0.1rem;
    }
    .titleh1,.titleh2,.titleh3 {
        padding-right: 2px;
        margin-right: -0.1rem;
    }
    .heading {
        margin-top: 140px;
    }
    
    .heading2{
        margin-top: 140px;
    }

    .mobileimagesource{
        width: 100%;
        object-fit: cover;
        height: 350px;
    }
   
}

@media (max-width: 440px) {


    .title1,.title2,.title3,.titleh1,.titleh2,.titleh3{
        font-size: 9px;
        line-height: 18px;
    }

    .heading,.heading2 {
        margin-top: 50px;
    }

    .heading {
        margin-top: 120px;
    }
    
    .heading2{
        margin-top: 120px;
    }

    .mobileimagesource{
        width: 100%;
        object-fit: cover;
        height: 290px;
    }
    
}

@media (max-width: 360px) {
    .heading {
        margin-top: 100px;
    }
    
    .heading2{
        margin-top: 100px;
    }

    .mobileimagesource{
        width: 100%;
        object-fit: cover;
        height: 260px;
    }

}

@media (max-width: 360px) {
    .heading {
        margin-top: 80px;
    }
    
    .heading2{
        margin-top: 80px;
    }

    .mobileimagesource{
        width: 100%;
        object-fit: cover;
        height: 220px;
    }
    .title1,.title2,.title3,.titleh1,.titleh2,.titleh3{
        font-size: 7px;

        line-height: 20px;

    }
    .rocket {
        width: 12px;
        height: 12px;
    }
}




