.container{
    width: 100%;
    /* height: 100%; */
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
}
.section{
    width: 90%;
    /* height: 90%; */
   
    justify-content: center;
 
    display: flex;
    margin-top: 3rem;
}
.box1{

    display: flex;
    justify-content: center;
    flex-direction: column;
   /* height: 50%; */
}
.icons1{
    display: flex;
    justify-content: center;
    gap: 30px;
    /* margin-top: -1.5rem; */
 
}
.heading{
    text-align: center;
    font-family: Poppins;
    font-size: 33px;
    font-weight: 400;
   
    color: #FFFFFF;
}
@media (max-width: 581px) {
    .heading{
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-weight: 400;
       
        color: #FFFFFF;
    }
    .section{
        width: 90%;
        /* height: 90%; */
       
        justify-content: center;
     
        display: flex;
        margin-top: 0rem;
    }
}
@media (max-width: 348px) {
    .icons1{
        display: flex;
        justify-content: center;
        gap: 15px;
     
    }
}