.container {
    width: 100%;
    overflow: hidden;
  }
  
  .brands {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
  
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 30px;
  }
  
  .slider {
    margin: auto;
    /* overflow-x: auto;  */
    /* overflow-y: hidden;  */
    /* position: relative; */
    width: 100%;
    /* white-space: nowrap; */
    /* scrollbar-width: none; */
    /* padding-left: 30px; */
  }

  .slider::-webkit-scrollbar {
    display: none; 
  }
  
  .slidetrack {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
    /* padding-right: 30px; */
      
  }
  
  .box1 {
    width: 250px;
    /* height: 100%; */
    /* height: 300px; */
    border-radius: 30px;
  }
  
  .text_12 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 1rem;
    color: #707070;
  }
  
  .customer {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  
  .customer_name {
    margin-top: 3rem;
    font-family: Inter;
    font-size: 26px;
    font-weight: 400;
    line-height: 31.47px;
    color: #000000;
    height: 380px;
  }
  
  /* .mainImg{
    height:100%;
  } */
  /* Responsive styles */
  @media (max-width: 743px) {
    .box1 {
      width: 240px;
    }
    .text_12 {
      font-size: 15px;
      line-height: 20px;
    }
    /* .mainImg{
      height: 370px;
    } */
  }
  
  @media (max-width: 450px) {
    .box1 {
      width: 225px;
    }
    .text_12 {
      font-size: 15px;
    }
    /* .mainImg{
      height: 280px;
    } */
  }
  