.container {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    position: fixed;
    /* Fixed position to keep header on top */
    top: 0;
    /* Position at the top of the viewport */
    left: 0;
    right: 0;
    z-index: 100000;
    background-color: #191919;
    /* opacity: 0.9; */
}

.mainHeader {
    margin: auto;
    width: 90%;
    height: 99px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 99;
}

.mainHeader img {
    padding: 4px;
    width: 111px;
    height: auto;
    z-index: 99;

}

.Logo1 {
    font-size: 62px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #59DB54;
    font-family: inter;
    z-index: 99;

}

.connect {
    padding: 13px 18px;
    background-color: transparent;
    font-family: inter;
    font-size: 20px;
    font-weight: 400;
    border: none;
    width: fit-content;
    transition: 0.3s ease-in;
    margin-left: 20px;
    border-radius: 15px;
    z-index: 99;
    cursor: pointer;
    border: 1px solid #59DB54;
    color: #59DB54;

}

.connect:hover {
    background-color: #59DB54;
    z-index: 99;
    color: white;
    border: 1px solid white;

}

.navmenu {
    transition: 0.3s ease-in;
    color: white;
    font-family: inter;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    padding: 10px;
    z-index: 99;

}

.navmenu:hover,
.service:hover {
    color: #59DB54;
}

.rightNav {
    display: inline-flex;
    /* margin-left: 150px; */
    transition: all ease-in 0.6s;
    gap: 40px;
    z-index: 99;
}

.mainHeader svg {}

.hamburger {
    display: none;
}

.dropdown {
    display: inline-block !important;
    margin-left: 10px;
    margin-top: 2px;
}

.page {
    position: relative;
}

.list {
    display: none;
    position: absolute;
    width: 240px;
    background-color: #fff;
    justify-content: flex-end;
    margin-top: 8px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.service {
    color: black;
    font-weight: 600;
    transition: 0.3s ease-in;
    text-decoration: none;
    font-size: 14px;
    padding: 5px;
    text-transform: capitalize;
}

.pages:hover .list {
    display: flex;
    flex-direction: column;
}

.menuflex {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #59DB54; */
}

.mobile{
    display: none;
    margin-left: 12px;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .navmenu {
        font-size: 13px;
        margin-left: 0px;
    }

    .connect {
        font-size: 16px;
        padding: 15px 26px;
        margin-left: 10px;
    }

    .rightNav {
        gap: 0px;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .navmenu {
        font-size: 16px;
    }

    .connect {
        font-size: 16px;
        padding: 15px 26px;
        margin-left: 10px;
    }

}



@media (max-width: 768px) {

    .dropdown {
        margin-left: 70px;
    }

    .mobile{
        display: block;
    }
    .rightNav {
        margin-left: 25px;
        flex-direction: column;
        position: absolute;
        background: black;
        margin-top: 54px;
        height: 125vh;
        left: -100%;
        gap: 0px;
    }

    .rightNav a {
        margin-left: 10px;
    }

    .navmenu a {
        margin-left: 0px;
    }

    .opened {
        left: -30px !important;
    }

    .mainHeader {
        width: 100%;

        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        /* padding-bottom: 30px; */

    }

    .navmenu {
        font-size: 17px;
        width: 233px;
    }

    .connect {
        /* margin-right: 50px; */
        padding: 15px 23px;
        margin-left: 0px;
    }

    /* .mainHeader svg {
        display: block;
       
        width: 78px;
    } */
    .hamburger {
        display: block;
    }

    .mainHeader img {
        /* margin-left: 0%; */
        /* width: 70px; */


    }

    .list {
        width: 200px;
        display: flex;
        flex-direction: column;
    }

    .container {
        height: 90px;
    }

    .menuflex {
        /* width: 50%; */
        /* justify-content: space-between; */
    }
}

@media (max-width:600px) {

    .navmenu {
        font-size: 15px;
    }

    .connect {
        padding: 10px 16px;
        /* margin-right: 15px; */
        font-size: 11px;
        border-radius: 5px;
    }



    .menuflex {
        /* width: 50%; */
        /* justify-content: space-between; */
    }
}

@media (max-width: 420px) {
    /* .connect {
        padding: 15px 20px;
        margin-right: 25px;
    }

    .mainHeader {
        gap: 2px;
    }

    .mainHeader img {
        margin-left: 40%;
        width: 106px;

    } */
}

@media (min-width: 1279px) and (max-width: 1440px) {

    .mainHeader {
        height: 82px;
    }

    .mainHeader img {
        padding: 4px;
        width: 111px;
        height: auto;
    }

    .navmenu {
        font-size: 18px;
    }
}

@media (max-width: 460px) {
    .Logo1 {
        font-size: 42px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        color: #59DB54;
        font-family: inter;
    }
}