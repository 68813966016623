.container {
  /* height: 100%; */
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 6rem;
  align-items: center;
}

.section {

  width: 100%;
  gap: 110px;
  display: flex;
  padding: 0 50px;

}

.box1 {

  flex: 1;


}

.box2 {
  display: flex;
  margin-top: 3rem;
}

.mainImg {
  width: 605px;
  height: 534px;
  border-radius: 10px;
}

.heading {
  font-family: inter;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  color: #FFFFFF;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.vision_icon {
  width: 40px;
}

.vision_text {
  display: flex;
  margin-top: -1.5rem;
}

.head {
  margin-left: 15px;
  margin-top: -5px;
  font-family: inter;
  font-size: 20px;
  font-weight: 300;
  line-height: 38px;
  color: #FFFFFF;

}

.line {
  border: none;
  height: 2px;
  background-color: #59DB54;
  width: 100%;
}

@media (max-width: 1380px) {
  .mainImg {
    width: 505px;
    height: 434px;
    border-radius: 10px;
  }

  .box2 {
    display: flex;
    margin-top: 2rem;
  }

}

@media (max-width: 1183px) {
  .container {
    /* height: 100%; */
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 4rem;
    align-items: center;
  
  }

  .section {
    /* height: 90%; */
    width: 90%;
    gap: 10px;
    justify-content: center;
    display: flex;
    flex-direction:column-reverse;
    align-items: center;
  }

  .box2 {
    display: flex;
    margin-top: 0rem;
  }
  .box2{
    margin-top: 3rem;
  }
}

@media (max-width: 680px) {
  .section {
    padding: 0 0px;
  }

  .mainImg {
    width: 98%;
    height: 334px;
    border-radius: 10px;
  }

  .heading {
    font-family: inter;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    color: #FFFFFF;
  }

  .head {
    margin-left: 15px;
    margin-top: -5px;
    font-family: inter;
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    color: #FFFFFF;
  }

  .container {
    /* height: 100%; */
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 0rem;
    align-items: center;

  }
 

}

@media (max-width: 415px) {
  .mainImg {
    /* width: 305px; */
   
    height: 234px;
    border-radius: 10px;
  }

  .heading {
    font-family: inter;
    font-size: 22px;
    font-weight: 500;

    color: #FFFFFF;


  }

  .head {
    margin-left: 15px;
    margin-top: -5px;
    font-family: inter;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    color: #FFFFFF;
  }
}