.container {
  width: 100%;
  /* height: 70vh; */
  background-image: url(Untitled12.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin-top: 5rem;
  justify-content: center;
  display: flex;
  margin-bottom: 15rem;
  height: 500px;
}

.section {
  width: 80%;

  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.box1 {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: 18rem;

}

.heading {
  font-family: Poppins;
  font-size: 52px;
  font-weight: 500;
  color: white;
  line-height: 0px;

}

.head {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 300;
  color: white;
  line-height: 0px;
  margin-top: 1rem;
}




.box2 {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgb(241, 225, 194);
  background: linear-gradient(90deg, rgba(241, 225, 194, 1) 0%, rgba(247, 207, 173, 1) 56%, rgba(252, 188, 152, 1) 82%);
  padding: 45px;
  border-radius: 10px;
  justify-content: center;
  margin-top: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.formGroup {
  display: flex;
  gap: 50px;

}

.input {
  flex: 1;
  background-color: transparent;
  color: #0a0a0a;

}

.input,
.textarea {
  border: none;
  border-bottom: 2px solid #0a0a0a;
  /* Adjust color as needed */
  padding: 15px 0px;
  /* Adjust padding to your preference */
  margin-bottom: 0px;
  /* Space between elements */
  outline: none;
  font-size: 18px;
  /* Adjust font size as needed */
  background-color: transparent;
  font-family: Roboto;
  color: #0a0a0a;
}



.textarea {
  width: 100%;
  height: 70px;



}

.submitButton {
  padding: 15px 32px;
  color: white;
  background-color: #59DB54;
  font-family: inter;
  font-size: 20px;
  font-weight: 400;
  /* line-height: 24px; */
  border: none;
  width: fit-content;
  transition: 0.3s ease-in;

  border-radius: 15px;
}

.submitButton:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: #59DB54;
  border: 1px solid #59DB54;
}

.formbutton {
  justify-content: center;
  display: flex;
  margin-top: 1.5rem;
}

.input::placeholder {
  font-family: Roboto;
  color: #0a0a0a;
  font-size: 20px;
}

.textarea::placeholder {
  font-family: Roboto;
  color: #0a0a0a;
  font-size: 20px;
}

@media (max-width: 830px) {
  .formGroup {
    display: grid;
    gap: 50px;

  }

  .container {


    margin-bottom: 20rem;


  }
}

@media (max-width: 585px) {
  .box2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgb(241, 225, 194);
    background: linear-gradient(90deg, rgba(241, 225, 194, 1) 0%, rgba(247, 207, 173, 1) 56%, rgba(252, 188, 152, 1) 82%);
    padding: 25px;
    border-radius: 10px;
    justify-content: center;

  }

  .heading {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    color: white;


  }

  .head {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 300;
    color: white;

    margin-top: 1rem;
  }

  .container {


    margin-bottom: 10rem;
    height: 700px;

  }
}

@media (max-width: 393px) {
  .heading {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    color: white;


  }

  .head {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 300;
    color: white;

    margin-top: 1rem;
  }

  .input::placeholder {
    font-family: Roboto;
    color: #0a0a0a;
    font-size: 17px;
  }

  .textarea::placeholder {
    font-family: Roboto;
    color: #0a0a0a;
    font-size: 17px;
  }

  .submitButton {
    padding: 10px 22px;
    color: white;
    background-color: #59DB54;
    font-family: inter;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 24px; */
    border: none;
    width: fit-content;
    transition: 0.3s ease-in;

    border-radius: 10px;
  }

  .submitButton:hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: #59DB54;
    border: 1px solid #59DB54;
  }

  .container {

    margin-bottom: 6rem;
    height: 550px;

  }
  .box1 {
  
  
    margin-top: 11rem;
  
  }
}