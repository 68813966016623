.container {

    width: 100%;
    justify-content: center;
    display: flex;
    /* padding-top: 5rem; */
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;
    overflow-x: hidden;
}

.section{
    display: flex;
    gap: 40px;
    width: 90%;
    margin-top: 5rem;
}
.section1{
    display: flex;
    gap: 40px;
    width: 90%;
    margin-top: 5rem;
}

.box1,.box2 {
    flex: 1;

}

.heading {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 400;
    line-height: 80px;
    color: #FFFFFF;
    /* text-align: center; */
    margin-top: -0.5rem;
}

.head {
    font-family: inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
    color: #FFFFFF;
    margin-top: -1.5rem;
    /* text-align: justify; */
}

.image{
    object-fit: contain;
    width: 100%;
}

@media (max-width: 1350px) {
  
    .heading {

        font-size: 30px;
        font-weight: 500;
        line-height: 70px;
        color: #FFFFFF;
        padding-top: 2rem;
    }

    .head {
        font-family: inter;
        font-size: 20px;
        font-weight: 300;
        line-height: 38px;
        color: #FFFFFF;
        margin-top: -1.5rem;

    }

}


@media (max-width: 1250px) {
    .section {
        flex-direction: column-reverse;
        gap: 0px;
        width: 90%;
        margin-top: 2rem;
    }
    .section1 {
        flex-direction: column;
        gap: 0px;
        width: 90%;
        margin-top: 2rem;
    }
    .image{
        object-fit: contain;
        width: 70%;
        margin-top: 2rem;
    }
}

@media (max-width: 785px) {

    

    
    .container {
        margin-top: 0rem;
        margin-bottom: 0;
    }
    .image{
        width: 80%;
    }
    .section {
      
        margin-top: 4rem;
    }
}

@media (max-width: 680px) {
   
  
    .heading {
      font-size: 26px;
   
    }
  
    .head {
     
      font-size: 15px;
     
    }
    .section {
      
        margin-top: 3rem;
    }
    .section1 {
      
        margin-top: 0rem;
    }
   
    .head {
    
        line-height: 28px;
       

    }
    .image{
        width: 90%;
    }
  
  }

@media (max-width: 400px) {
    .heading {
        font-size: 22px;
        font-weight: 500;
        line-height: 50px;
        color: #FFFFFF;
        margin-top: -0.5rem;
    }

    .head {
        font-size: 12px;
        font-weight: 400;
        line-height: 21px;
        color: #FFFFFF;
        margin-top: -1.5rem;

    }
}