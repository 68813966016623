.container {

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.contant {
    width: 90%;
    color: rgb(255, 255, 255);
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;

}



.sec {
    display: flex;
    flex: 1;
    gap: 50px;
    justify-content: space-between;
}




.mainImg {
    width: 100px;
}

.heading {
    font-family: Poppins;
    font-weight: 500;
    font-size: 25px;
}

.pages {
    flex: 1;
}

.pagess {
    width: 95%;
}

.page {
    /* margin-top: -1.5rem; */

}

.pageLink {
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-decoration: none;
    display: flex;
}


.main {
    width: 100%;
}

.contant2 {
    color: #000000;
    font-family: Rubik;
    font-weight: 400;
    font-size: 14px;
    line-height: 39.23px;
    letter-spacing: 3%;
    color: #F4F4F4;
    text-decoration: none;
    margin-bottom: 2rem;
}

.icons {
    gap: 50px;
    margin-top: 20px;
    display: flex;
    /* width: 60%; */
}

.csection {

    width: 90%;

    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.isection {
    display: flex;
    gap: 15px;



}

.line {
    border: none;
    height: 2px;
    background-color: #59DB54;
    width: 90%;
}



.p1 {
    color: #ffffff;
    margin-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
}

@media (max-width: 1151px) {
    .sec {
       flex-wrap: wrap;
    }

    .heading {
        font-family: Poppins;
        font-weight: 500;
        font-size: 20px;
       
    }
    .pageLink{
        font-size: 15px;
    }
}

@media (max-width: 1101px) {
    .heading {
        font-family: Poppins;
        font-weight: 500;
        font-size: 22px;
        /* line-height: 30.42px; */

    }

    

    .sec {
        display: flex;
        gap: 5px;
    }
}
@media (max-width: 799px) {
    .container {

        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .sec {
        display: grid;
        gap: 5px;
    }


    .csection {

        /* width: 85%; */

        display: flex;
        justify-content: space-between;
        /* flex-direction: column-reverse; */
        /* align-items: center; */
    }

   
} 

 @media (max-width: 608px) {
   

   

    .csection {

        /* width: 85%; */

        display: flex;
        
         flex-wrap: wrap;
         flex-direction: column-reverse;
        
    }

   

    .section {
        width: 100%;
    }
    .container{
        display: flex;
        justify-content: center;
    }
    .contant {
        /* width: 85%; */
        color: rgb(255, 255, 255);
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        /* grid-template-columns: 2fr 1fr; */
    
    }
    .pagess{
        width: 100%;
        /* background-color: #59DB54; */
    }
    .pageLink{
        font-size: 12px;
    }
  
} 

