.container {
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 8rem;
}

.section {
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  justify-content: space-between;
}

.box1 {
  height: 80%;
  width: 40%;

  justify-content: left;
  display: grid;
  flex-direction: column;
  overflow: visible;
  /* Ensure overflow is visible */
  white-space: nowrap;
  /* Prevents text from wrapping */
  position: relative;
  /* Ensure position is relative or static */
  margin-top: 2rem;
}

.box2 {
  height: 80%;
  width: 60%;

  justify-content: center;
  display: flex;

}

.secondImage {
  position: absolute;
  /* width: 230px; */
  height: 88%;
  transform: rotate(0deg);
  margin-left: 83px;
  margin-top: 40px;
  border-radius: 25px;
}

.mainImg {
  width: 655.04px;
  height: 528.1px;
  overflow-x: hidden;
}

.heading {
  font-family: inter;
  font-size: 70px;
  font-weight: 400;
  line-height: 89px;
  color: #ffffff;
  padding-left: 5rem;
  margin-top: -0.5rem;
}

.text {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 19px;
  color: #ffffff;
  padding-left: 5rem;
  margin-top: -2rem;
  width: 90%;
}

.button1 {
  padding: 15px 32px;
  color: white;
  background-color: #59db54;
  font-family: inter;
  font-size: 15px;
  font-weight: 400;
  /* line-height: 24px; */
  border: none;
  width: fit-content;
  transition: 0.3s ease-in;
  margin-left: 5rem;
  border-radius: 15px;
  cursor: pointer;
}

.button1:hover {
  background-color: #59db54;
  color: white;
  border: 1px solid white;
}

.box1,
.box2 {
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
  opacity: 0;
}

.box1.hidden {
  transform: translateY(30px);
  /* Adjust as needed */
  overflow: hidden;
}

.box2.hidden {
  transform: translateY(-40px);
  /* Adjust as needed */
  overflow: hidden;
}

.box1.visible {
  transform: translateX(0);
  opacity: 1;
}

.box2.visible {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 1189px) {
  .heading {
    font-family: inter;
    font-size: 60px;
    font-weight: 400;
    line-height: 69px;
    color: #ffffff;
    padding-left: 5rem;
    margin-top: 1rem;
  }

  .mainImg {
    width: 600.04px;
    height: 500.1px;
  }
}

@media (max-width: 1049px) {
  .section {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .box2 {
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 3rem;
  }

  .box1 {
    height: 80%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: visible;
    white-space: none;
    position: none;
    margin-top: 2rem;
  }

  .heading {
    font-family: inter;
    font-size: 70px;
    font-weight: 400;
    line-height: 89px;
    color: #ffffff;
    padding-left: 0rem;
    margin-top: -0.5rem;
    text-align: center;
  }

  .text {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 19px;
    color: #ffffff;
    padding-left: 0rem;
    margin-top: -2rem;
    text-align: center;
  }

  .button1 {
    padding: 13px 22px;
    color: white;
    background-color: #59db54;
    font-family: inter;
    font-size: 15px;
    font-weight: 400;
    /* line-height: 24px; */
    border: none;
    width: fit-content;
    transition: 0.3s ease-in;
    margin-left: 0rem;
    border-radius: 15px;
  }

  .button1:hover {
    background-color: #59db54;
    color: white;
    border: 1px solid white;
  }
}

@media (max-width: 928px) {
  .container {
    height: 100%;
    width: 100%;
    justify-content: center;

    display: flex;
    margin-top: 4rem;

  }
}

@media (max-width: 816px) {
  .container {
    height: 100%;
    width: 100%;
    justify-content: center;

    display: flex;
    margin-top: 5rem;


  }

  .heading {
    font-family: inter;
    font-size: 40px;
    font-weight: 400;
    line-height: 49px;
    color: #ffffff;
    padding-left: 0rem;
    margin-top: 0rem;
    text-align: center;
  }

  .text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 39px;
    color: #ffffff;
    padding-left: 0rem;
    margin-top: -0.5rem;
    text-align: center;
  }

  .box1 {
    margin-top: 0rem;
  }
}

@media (max-width: 626px) {
  .mainImg {
    /* width: 400.04px; */
    height: 400.1px;
    width: 120%;
    overflow-x: hidden;
  }

  .secondImage {
    /* width: 200px; */
    height: 93%;
    transform: rotate(0deg);
    margin-top: 25px;
    border-radius: 25px;
    margin-right: 20px;

  }

}

@media (max-width: 472px) {
  .container {
    height: 100%;
    width: 100%;
    justify-content: center;

    display: flex;
    margin-top: 3rem;

  }

  .heading {
    font-family: inter;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    color: #ffffff;
    padding-left: 0rem;
    margin-top: 0rem;
    text-align: center;
  }

  .text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    color: #ffffff;
    padding-left: 0rem;
    margin-top: -0.5rem;
    text-align: center;
  }

  .mainImg {
    height: 380.1px;
    width: 120%;
    overflow-x: hidden;
  }

  .secondImage {
    /* width: 150px; */
    height: 88%;
    transform: rotate(0deg);
    margin-top: 40px;
    border-radius: 25px;
    margin-right: 50px;
    object-fit: inherit;
  }
}

@media (max-width: 348px) {
  .heading {
    font-family: inter;
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
    color: #ffffff;
    padding-left: 0rem;
    margin-top: 0rem;
    text-align: center;
  }

  .text {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 29px;
    color: #ffffff;
    padding-left: 0rem;
    margin-top: -0.5rem;
    text-align: center;
  }

  .mainImg {
    height: 270.1px;
    width: 120%;
    overflow-x: hidden;
  }

  .secondImage {
    /* width: 110px; */
    height: 88%;
    transform: rotate(0deg);
    margin-top: 20px;
    border-radius: 25px;
    margin-right: 57px;

  }

}